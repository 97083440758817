<style lang="less">
  @import "../assets/css/variables";

  .code-button {
    margin-left: 7px;
    background-color: @active-color;
    border-radius: 3px;
    width: 100px;
    color: #fff;
  }
</style>

<template>
  <div>
    <el-row>
      <!--<div :span="12"><el-input  :disabled="true" v-model="user.mobile"></el-input></div>
      <div :span="12"><router-link  :span="12">重新绑定</router-link></div>-->
      <el-col :span="16">
        <el-input v-model="code" @change="updateValue" :maxlength="6"></el-input>
      </el-col>
      <el-col :span="8" class="text-center">
        <el-button @click="sendMsg" :plain="true" :disabled="btnDisabled"
                   class="code-button" :span="12">
          {{buttonStr}}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

  import Vue from 'vue';

  let timer = -1;

  export default {
    //components: {ElButton},
    name: 'checkcode',
    data() {
      return {
        code: '',
        maxTimer: 120, //状态复位的倒计时
        btnDisabled: true, //获取验证码的按钮状态  false 可操作   true 不可操作
        buttonStr: '获取验证码'
      }
    },
    props: {
      maxtime: {
        type: Number,
        default: function () {
          return 120
        }
      },
      disabled: {
        type: Boolean,
        default: function () {
          return true
        }
      }
    },
    methods: {
      /**
       * [把值传递给父节点]
       * @return {[type]} [description]
       */
      updateValue(value){
        this.$emit('input', value);
      },
      /**
       * [询问父组件是否可以发送短信（主要需要判断手机号是否合法）]
       * @return {[type]} [description]
       */
      /*requestCanMsg: function(_e) {
       this.$dispatch('check-phone', _e);
       _e.preventDefault();
       },*/
      /**
       * [发生短信手机获取验证]
       * @param  {[event]} _$tag [event]
       * @return {[type]} [description]
       */
      sendMsg: function () {
        this.$emit('senMsg');
        this.countdown();
        this.btnDisabled = true;
      },

      /**
       * [倒计时限制每次发送短信的间隔]
       * @param  {[zepto object]} _$tag [发送验证码按钮的dom]
       * @return {[null]}       [null]
       */
      countdown: function () {
        this.buttonStr = this.maxTimer + 's';
        timer = setTimeout(() => {
          this.maxTimer--;
          if (this.maxTimer < 1) {
            clearTimeout(timer);
            this.btnDisabled = false;
            this.buttonStr = '获取验证码';
            this.maxTimer = this.maxtime;
          }
          this.countdown();
        }, 1000);
      }

    },
    created(){
      this.maxTimer = this.maxtime;
      this.btnDisabled = this.disabled;
    },
    watch: {
      disabled(newVal, oldVal){
        if (!newVal)
          this.buttonStr = '获取验证码';
        this.btnDisabled = this.disabled;
      }
    }


  }

</script>
