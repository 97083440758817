<style lang="less" scoped>

  @import "../../assets/css/variables";

  .content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .inner-container {
    border-bottom: 1px dashed #d1d1d1;
    padding-bottom: 36px;
  }

  .info-content {
    margin: 60px auto 0 auto;
    width: 400px;
  }

  .step-wrapper {
    margin: 0 auto 40px 40px; //多了150px在右边
  }

  .center {
    margin: 0 auto;
    width: 400px;
  }

  .bottom {
    width: 100%;
    height: 100px;
  }

</style>

<template :is="view">
  <div class="content">
    <nomenu-header></nomenu-header>
    <div class="container inner-container">
      <div class="page-nav-title">
        <div >忘记密码</div>
      </div>
      <div class="info-content ">
        <div class="step-wrapper">
          <el-steps :space="170" :center="true" :align-center="true" :active="step" finish-status="success">
            <el-step title="验证身份"></el-step>
            <el-step title="设置新密码"></el-step>
            <el-step title="完成"></el-step>
          </el-steps>
        </div>
        <el-form v-if="step===0" ref="form" :rules="rules" :model="pwdData" label-width="80px">
          <el-form-item class="label-3to4" label="用户名" prop="username">
            <el-input @change="handleNumChange" :maxlength="11" v-model="mobileData.username"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile">
            <el-input @change="handleNumChange" :maxlength="11" v-model="mobileData.mobile"></el-input>
          </el-form-item>
          <el-form-item label="验证码" class="m-b-xxl label-3to4" prop="validate">
            <check-code ref="valicodeComp" :maxtime="600"
                        @input="mobileData.validate=arguments[0]" :disabled="!mobileValid">
            </check-code>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="!mobileValid" class="w-full" type="primary" size="large" @click="onNext">下一步


            </el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="step===1" ref="pwdForm" :rules="rules" :model="pwdData" label-width="80px">
          <el-form-item label="新密码" prop="newPassword" class="label-3to4">
            <el-input type="password" :maxlength="30" v-model="pwdData.newPassword" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item class="m-b-xxl" label="确认密码" prop="rpPwd">
            <el-input type="password" :maxlength="30" @change="handleRppwdInput" v-model="pwdData.rpPwd"
                      auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="!formValid" class="w-full" type="primary" size="large" @click="onSubmit">保存

            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <justcr-footer class="bottom "></justcr-footer>
  </div>

</template>

<script>

  import Vue from 'vue';
  import captchaImage from '../../components/captchaimage';
  //webpack 会将此转为base64 然后就可以用了，src一个静态本地想对路径图片的时候会被webpack url-loader拦截
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import {API_ROOT}from '../../config';
  import {checkForget, forgetSumbitPwd} from '../../services/ucenter';
  import {Notification} from 'element-ui';
  import justcrFooter from "../common/footer-justcr.vue";
  import noMenuHeader from "../common/header-nomenu.vue";
  import {valiPhone, valiCheckCode, valiPassword} from "../../utils/validator/element";
  import Checkcode from '../../components/checkcode.vue';

  //Vue.component('search-box', SearchBox)
  export default {
    beforeRouteEnter(to, from, next) {
      next();
    },
    components: {
      'justcr-footer': justcrFooter,
      'nomenu-header': noMenuHeader,
      'check-code': Checkcode
    },
    data() {
      const valiRpPwd = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.pwdData.newPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        formValid: false,
        step: 0,  //第一步
        mobileValid: false,
        mobileData: {
          mobile: '',
          validate: ''
        },
        phoneRules: {
          mobile: [
            {validator: valiPhone, trigger: 'blur'}
          ],
          validate: [
            {validator: valiCheckCode, trigger: 'blur'}
          ]
        },
        pwdData: {
          newPassword: '',
          rpPwd: ''
        },
        rules: {
          newPassword: [
            {validator: valiPassword, trigger: 'blur'}
          ],
          rpPwd: [
            {validator: valiRpPwd, trigger: 'blur'}
          ]
        }

      }
    },
    methods: {
      /**
       * [手机输入回调]
       */
      handleNumChange(){
        //if(isNaN(this.phone))
        let mobile = this.mobileData.mobile;
        if ((mobile + '').length === 11) {
          if (/^1[34578]\d{9}$/.test(mobile)) {
            this.mobileValid = true;//验证通过，显示获取验证码按钮和保存
          }
        } else {
          if (isNaN(mobile)) {
            Vue.nextTick(() => {  //解决掉input后直接赋值model视图不更新问题
              this.mobileData.mobile = '' // true
            })
          }
        }
      },


      /**
       * [从子组件得到向请求后台向手机发送验证码的事件]
       */
      async senMsg(){
        const sendData = await sendMsgToPhone(this.mobileData.mobile);
        if (sendData && sendData.errorCode === 0 && sendData.data) {
          Notification.success({
            title: '成功',
            message: '短信验证码已经发送成功，请注意查看手机'
          })
        }
      },
      /**
       * [验证身份]
       * @return {[type]} [description]
       */
      async checkForget() {
        const checkData = await checkForget(this.mobileData);
        if (checkData && checkData.errorCode === 0
          && checkData.data) {
          if (checkData.data.result) {
            this.step = 1;
          } else {
            Notification.error({
              title: '验证错误',
              message: checkData.errorMessage
            });
          }
        }
      },
      /**
       * 下一步
       */
      onNext(){
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.checkForget();
          }
        });
      },
      /**
       * [重复密码输入的handle]
       */
      handleRppwdInput(value){
        this.formValid=value === this.pwdData.newPassword;
      },
      /**
       * 提交修改密码表单
       */
      onSubmit(){
        this.$refs.pwdForm.validate((valid) => {
          if (valid) {
            this.submitPassword();
          }
        });
      },
      /**
       * [验证身份]
       * @return {[type]} [description]
       */
      async submitPassword() {
        const checkData = await forgetSumbitPwd(this.pwdData.newPassword);
        if (checkData && checkData.errorCode === 0
          && checkData.data) {
          if (checkData.data.result) {
            Notification.success({
              title: '成功',
              message: '密码修改成功，自动调整到登录'
            });
            window.router.push('/login');
          } else {
            Notification.error({
              title: '失败',
              message: checkData.errorMessage
            });
          }
        }
      },

    }
  }

</script>
