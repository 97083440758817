<style>

.captchaImage {
    height: 40px;
    width: 100%;
    line-height: 40px;
    vertical-align: middle;
}

.captchaImage img {
    max-height: 100%;
    max-width: 100px;
}

</style

<template>



</template>

<script>

import Vue from 'vue';

let timer = -1;

export default {
    props: ['code'],
    data() {
        return {
        }
    },
    methods: {
        /**
         * [刷新验证码的图片]
         * @param  {[type]} _e [description]
         * @return {[type]}    [description]
         */
        getCaptchaImage: function() {
            let $tag = $('.captchaImage');
            $tag.children().eq(0).attr("src", this.server_path + "/captchaImage");
        }
    },
    activate: function(done) {
        done();
    }

}

</script>
